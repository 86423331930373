import { ThemeVarsProps } from '@atlas/statics/lib/types/variables';

/**
 * Converts theme-vars.json object into CSS vars.
 *
 * @param {ThemeVarsProps} jsonObj theme-vars.json object
 * @param {string} prefix Varname prefix. Used for recursive purposes.
 * @param {string} parentPath Parent path. Used for recursive purposes.
 * @returns CSS string with vars declarations.
 */
export function themeVarsToCss(
	jsonObj: ThemeVarsProps,
	prefix: string = '',
	parentPath: string = '',
) {
	let cssString = '';
	for (const key in jsonObj) {
		/**
		 * NOTE: This IF avoids 'default' level-0 prop automatically added by
		 * import.
		 * @see mono-repo-visas/apps/statics/lib/types/variables.ts
		 * @link
		 * https://stackoverflow.com/questions/66189827/importing-objects-from-json-file-into-angular-adds-default-object-wrapper
		 * **/
		if (key !== 'default' || parentPath.length > 0) {
			const prop = (jsonObj as any)[key]; // FIXME: wtf TS ????
			const currentPath = parentPath
				? `${parentPath}-${key}`
				: key;
			const cssVarName = `--${
				prefix ? `${prefix}-` : ''
			}${currentPath}`;

			if (typeof prop === 'object') {
				cssString += themeVarsToCss(prop, prefix, currentPath);
			} else {
				cssString += `${cssVarName}: ${prop};\n`;
			}
		}
	}
	return cssString;
}
