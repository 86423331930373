'use client';
import { ApolloProvider } from '@apollo/client';
import { initApolloClient } from '@atlas/statics/lib/apollo/client';
import StyledComponentRegistry from '@atlas/statics/components/StyleSheetManager';
import { GlobalStyle } from '@atlas/ui-library/lib/GlobalStyle';
import {
	AbstractIntlMessages,
	NextIntlClientProvider,
} from 'next-intl';
import {
	QueryClient,
	QueryClientProvider,
	isServer,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
	AppContext,
	AppContextProps,
} from '@atlas/statics/services/AppContext';
import { timeZone } from '@atlas/statics/i18n/config';

function makeQueryClient() {
	return new QueryClient({
		defaultOptions: {
			queries: {
				// With SSR, we usually want to set some default staleTime
				// above 0 to avoid refetching immediately on the client
				staleTime: 5 * 60 * 1000,
				refetchInterval: 5 * 60 * 1000,
			},
		},
	});
}

let browserQueryClient: QueryClient | undefined = undefined;
function getQueryClient() {
	if (isServer) {
		// Server: always make a new query client
		return makeQueryClient();
	} else {
		// Browser: make a new query client only if we don't already have one
		// This is very important so we don't re-make a new client if React
		// suspends during the initial render. This may not be needed if we
		// have a suspense boundary BELOW the creation of the query client.
		if (!browserQueryClient) {
			browserQueryClient = makeQueryClient();
		}
		return browserQueryClient;
	}
}

// NOTE: Avoid useState when initializing the query client if you don't
//       have a suspense boundary between this and the code that may
//       suspend because React will throw away the client on the initial
//       render if it suspends and there is no boundary
const queryClient = getQueryClient();

const Providers = ({
	dataCTX,
	children,
	messages = {},
	locale = 'en',
	previewMode = false,
}: {
	dataCTX: AppContextProps;
	children: React.ReactNode;
	messages?: AbstractIntlMessages;
	locale?: string;
	previewMode?: boolean;
}) => {
	const { themeVars } = dataCTX;
	const apolloClient = initApolloClient();
	return (
		<QueryClientProvider client={queryClient}>
			<ApolloProvider client={apolloClient}>
				<AppContext.Provider value={dataCTX}>
					<StyledComponentRegistry>
						<GlobalStyle $variables={themeVars} />
						<NextIntlClientProvider
							locale={locale}
							messages={messages}
							timeZone={timeZone}>
							{children}
						</NextIntlClientProvider>
					</StyledComponentRegistry>
				</AppContext.Provider>
				{!previewMode && <ReactQueryDevtools />}
			</ApolloProvider>
		</QueryClientProvider>
	);
};

export default Providers;
