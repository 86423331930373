import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

/**
 * Checks for errors in the server's response. Log any GraphQL or network errors that occurred.
 * @var graphQLErrors GraphQL related errors.
 * @var networkError Network related errors.
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) => {
			console.error(`[GraphQL error]: `, { message });
			console.error(`[GraphQL error]: `, { locations });
			console.error(`[GraphQL error]: `, { path });
		});
	}
	if (networkError) console.error(`[Network error]: ${networkError}`);
});

/**
 * Sends each GraphQL operation to your server.
 * @param uri Server URL (must be absolute).
 * @param credentials Additional fetch() options like `credentials` or `headers`.
 */
const httpLink = new HttpLink({
	uri: `${process.env!.NEXT_PUBLIC_VISAS_CONTENT_API}/graphql`,
	credentials: 'same-origin',
});

export function initApolloClient() {
	const ELEMENT_TYPES = [
		'Title',
		'Body',
		'Button',
		'Link',
		'Image',
		'AddComponent',
	];
	const COMPONENT_TYPES = [
		'Jumbotron',
		'InfoBlock',
		'InfoBox',
		'Banner',
		'Table',
		'Steps',
		'CountryCards',
		'Header',
		'Navbar',
		'Footer',
		'Disclaimer',
		'Cookies',
		'Accordion',
	];
	return new ApolloClient({
		connectToDevTools: true,
		link: from([errorLink, httpLink]),
		cache: new InMemoryCache({
			// @see https://www.apollographql.com/docs/react/caching/cache-configuration/#possibletypes
			possibleTypes: {
				ElementType: ELEMENT_TYPES,
				ElementTaxonomy: ELEMENT_TYPES,
				ComponentType: COMPONENT_TYPES,
				ComponentTaxonomy: COMPONENT_TYPES,
			},
		}),
	});
}
